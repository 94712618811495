import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import getListingSlug from "../utils/getListingSlug"

const Wrapper = styled.div`
  .text-wrapper {
    max-width: 1040px;
    margin: 0 auto;
    padding: 0 2rem;
    text-align: center;

    .heading {
      margin-bottom: 2rem;
      width: 100%;
      z-index: 10;
      text-align: center;
      font-family: "Conv_Resolve-ExtraboldCn";
      text-transform: uppercase;
      font-size: 4rem;
      letter-spacing: 0.25rem;
    }

    .text {
      font-family: var(--font-reg);
      color: var(--black);
      z-index: 1;
      position: relative;
      /* margin-bottom: 4rem; */
      width: 100%;
      font-size: 0.9rem;
      line-height: 1.5rem;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    padding: 2rem;
    width: 100%;

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .offer {
      background: var(--brandColor);
      border-radius: 0 0 1rem 1rem;

      .offer-detail {
        background: var(--brandColor);
        padding: 1rem;
        line-height: 1.5rem;
        border-radius: 0 0 1rem 1rem;
      }
      a.main {
        aspect-ratio: 16/11;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: black;
        color: white;
      }

      a {
        text-decoration: none;
      }

      .heading {
        width: 100%;
        z-index: 10;
        text-align: center;
        font-family: "Conv_Resolve-ExtraboldCn";
        text-transform: uppercase;
        font-size: 1.8rem;
        letter-spacing: 0.25rem;
      }

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.7;
      }
    }
  }
`

export default function OffersLeeds(pageProps) {
  const { data, pageContext } = pageProps

  return (
    <Wrapper>
      <div className="text-wrapper">
        <h2 className="heading">{pageContext.slug} Offers</h2>
        <div className="text">
          We work closely with independent businesses to bring you exceptional
          offers across Yorkshire, making your journey even more worthwhile and
          giving you more value for money. Here's some, keep checking our
          website regularly as they will be updated online as well as our
          socials.
        </div>
      </div>
      <div className="grid">
        {data.allWpListing.nodes.map(listing => (
          <article className="offer">
            <Link to={getListingSlug(listing)} className="main">
              <div className="heading">{listing.title}</div>
              <GatsbyImage
                image={
                  listing.acf_listings.image1.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={listing.name}
              />
            </Link>
            <div className="offer-detail">
              {listing.acf_listings.blackCardPromotion}
            </div>
          </article>
        ))}
      </div>
    </Wrapper>
  )
}

export const offerLeeds = graphql`
  query ($slug: String!) {
    allWpListing(
      sort: { order: ASC, fields: title }
      filter: {
        acf_listings: { blackCardPromotion: { ne: null } }
        listingsTagLocation: {
          nodes: { elemMatch: { wpParent: { node: { slug: { eq: $slug } } } } }
        }
      }
    ) {
      nodes {
        id
        title
        uri
        slug
        listingsTagLocation {
          nodes {
            slug
            wpParent {
              node {
                slug
              }
            }
          }
        }
        acf_listings {
          blackCardPromotion
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
